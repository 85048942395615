











import Vue from 'vue'
import Component from 'vue-class-component'
import BookingInfo from '@/components/booking/BookingInfo.vue'
import { vxm } from '@/store'
import Booking from '@/models/booking/Booking'

@Component({
  components: {
    BookingInfo,
  },
})
export default class Show extends Vue {
  public booking: Booking = null

  public created() {
    this.load()
  }

  private onUpdate() {
    this.load()
  }

  private load() {
    this.$axios
      .get('/v4/site/calendars/any/bookings/' + this.$route.params.id)
      .then((response) => {
        this.booking = new Booking(response.data.data)
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err)
      })
  }
}
